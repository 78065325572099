import request from '@/utils/request'
const baseURL = '/baseapi'
const applicationApi = {
  FormList: '/admin/application/list',
  FormInfo: '/admin/application/formInfo',
  FormInfoById: '/admin/application/formInfoById',
  SaveForm: '/admin/application/saveForm',
  DelForm: '/admin/application/delForm',
  VerifyForm: '/admin/application/verifyForm',
  Condition: '/admin/application/condition',
  FormExecList: '/admin/application/formExecList'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function formList (parameter) {
  return request({
    baseURL: baseURL,
    url: applicationApi.FormList,
    method: 'post',
    data: parameter
  })
}

export function formInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: applicationApi.FormInfo,
    method: 'post',
    data: parameter
  })
}

export function formInfoById (parameter) {
  return request({
    baseURL: baseURL,
    url: applicationApi.FormInfoById,
    method: 'post',
    data: parameter
  })
}

export function saveForm (parameter) {
  return request({
    baseURL: baseURL,
    url: applicationApi.SaveForm,
    method: 'post',
    data: parameter
  })
}

export function delForm (parameter) {
  return request({
    baseURL: baseURL,
    url: applicationApi.DelForm,
    method: 'post',
    data: parameter
  })
}

export function verifyForm (parameter) {
  return request({
    baseURL: baseURL,
    url: applicationApi.VerifyForm,
    method: 'post',
    data: parameter
  })
}

export function condition () {
  return request({
    baseURL: baseURL,
    url: applicationApi.Condition,
    method: 'get'
  })
}

export function formExecList (parameter) {
  return request({
    baseURL: baseURL,
    url: applicationApi.FormExecList,
    method: 'post',
    data: parameter
  })
}
